import Sortable from '@stimulus-components/sortable';
import HTTPWrapper from '../utilities/HTTPWrapper';

// tag each item with: data-sortable-target="item"
// and specify the update URL where that item can be patched
// e.g. <tr data-sortable-target="item" data-item-update-url="<%= whatever_path(eg.item_id) %>">
export default class extends Sortable {
  static targets = ['item'];

  connect() {
    super.connect();
  }

  onUpdate() {
    this.itemTargets.forEach((item, index) => {
      // V1 of this component assumes that the attribute on the property is called 'order'
      // if this needs varying for other models/classes, it could be configured as a
      // Stimulus value on this component, or we might want to standardize our naming.
      const patch = { order: index };
      HTTPWrapper.patch(item.dataset.itemUpdateUrl, patch);
    });
  }
}
