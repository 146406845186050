/* eslint-disable no-dupe-class-members */
import { Controller } from 'stimulus';
import { capitalize } from 'lodash';
import API from '../store/api';

const STATUS_ICON_MAP = Object.freeze({
  queued: 'clock outline grey',
  working: 'hourglass half orange',
  complete: 'check square green',
  failed: 'exclamation triangle red',
});

const TERMINAL_STATUSES = Object.freeze(['complete', 'failed', 'interrupted']);

const POLL_INTERVAL = 2000; // 2 secs

export default class extends Controller {
  static targets = [
    'jobName',
    'jobStatus',
    'jobPctComplete',
  ];

  static values = {
    jobId: String,
    jobName: { type: String, default: '-' },
    jobStatus: { type: String, default: '-' },
    jobPctComplete: { type: Number, default: 0 },
    redirectUri: String,
  }

  get consideredDone() { return TERMINAL_STATUSES.includes(this.jobStatusValue); }

  connect() {
    this.#getStatus(this.jobIdValue);
    this.poll = setInterval(() => this.#getStatus(this.jobIdValue), POLL_INTERVAL);
  }

  jobNameTargetConnected() { this.#renderJobName(this.jobNameValue); }
  jobNameValueChanged() { if (this.hasJobNameTarget) this.#renderJobName(this.jobNameValue); }
  jobStatusTargetConnected() { this.#renderJobStatus(this.jobStatusValue); }

  jobStatusValueChanged() {
    if (this.hasJobStatusTarget) {
      this.#renderJobStatus(this.jobStatusValue);

      if (this.jobStatusValue === 'working') {
        this.jobStatusTarget.classList.add('pulsing');
      } else {
        this.jobStatusTarget.classList.remove('pulsing');
      }
    }

    if (this.consideredDone || this.jobStatusValue === '?') this.#stopPolling();

    if (this.jobStatusValue === 'complete' && this.redirectUriValue) location.replace(this.redirectUriValue);
  }

  jobPctCompleteTargetConnected() { this.#renderJobPctComplete(this.jobPctCompleteValue); }

  jobPctCompleteValueChanged() {
    if (this.hasJobPctCompleteTarget) {
      this.#renderJobPctComplete(this.jobPctCompleteValue);

      if (this.jobPctCompleteValue === 0 || this.jobPctCompleteValue === 100) {
        this.jobPctCompleteTarget.classList.add('visibility-hidden');
      } else {
        this.jobPctCompleteTarget.classList.remove('visibility-hidden');
      }
    }
  }

  disconnect() { this.#stopPolling(); }
  #stopPolling() { clearInterval(this.poll); }

  #getStatus(jobId) {
    API.getBackgroundJobStatus(jobId)
      .then(({ data }) => {
        this.jobNameValue = data.worker;
        this.jobStatusValue = data.status;
        this.jobPctCompleteValue = data.pct_complete;
      })
      .catch(this.#handleError);
  }

  #renderJobName(value) { this.jobNameTarget.innerText = value; }
  #renderJobStatus(value) {
    this.jobStatusTarget.innerHTML = STATUS_ICON_MAP[value] ? `<i class="${STATUS_ICON_MAP[value]} icon"></i>` : `<p>${capitalize(value)}</p>`;
  }
  #renderJobPctComplete(value) { this.jobPctCompleteTarget.value = value; }

  #handleError() {
    this.jobNameValue = '?';
    this.jobStatusValue = '?';
    this.jobPctCompleteValue = 0;
  }
}
