/* eslint-disable no-dupe-class-members */
import { Controller } from 'stimulus';

// A generic accordion controller that can be used for any Semantic UI accordion
// Wrap the whole accordion with: <div class="ui styled accordion" data-controller="accordion">
// Wrap each item with: <div data-accordion-target="item"></div>
export default class extends Controller {
  static targets = ['item'];

  static values = {
    startAllClosed: { type: Boolean, default: true },
  }

  connect() {
    if (this.startAllClosedValue) {
      this.closeAll();
    }
  }

  show(event) {
    // User is clicking on an item that's already open
    if (event.currentTarget.parentElement.children[0].classList.contains('active')) {
      // Close it
      this.closeAll();
      return;
    }

    this.closeAll();
    [...event.currentTarget.parentElement.children].forEach((child) => {
      child.classList.add('active');
    });
  }

  closeAll() {
    this.itemTargets.forEach((item) => {
      [...item.children].forEach((child) => {
        child.classList.remove('active');
      });
    });
  }
}
