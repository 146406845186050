/* eslint-disable no-dupe-class-members */
import { Controller } from 'stimulus';
import _ from 'lodash';
import API from '../store/api';

const BAND_LEADER_SUPPORT_SELECT_PROMPT = 'Filter by Band Leader Support...';
const NO_BAND_LEADER_SUPPORTS_FOUND_TEXT = 'No Band Leader Supports Found';
const BAND_LEADER_SELECT_PROMPT = 'Filter by Band Leader...';
const NO_BAND_LEADERS_FOUND_TEXT = 'No Band Leaders Found';

export default class extends Controller {
  static targets = [
    'bandLeaderSupportSelect',
    'bandLeaderSupportSelectContainer',
    'bandLeaderSelect',
    'bandLeaderSelectContainer',
  ];

  // If the dom is rendered with results scoped to a band leader/support
  // (i.e., from a previous filter), these values identify the ids of those
  // individuals. These are used to preset results as 'selected'
  // in the respective select inputs.
  static values = {
    scopedBandLeaderSupportId: Number,
    scopedBandLeaderId: Number,
  }

  get selectedBandLeaderSupportId() {
    return parseInt(this.bandLeaderSupportSelectTarget.value, 10) || null;
  }

  get selectedBandLeaderId() {
    return parseInt(this.bandLeaderSelectTarget.value, 10) || null;
  }

  connect() {
    // eslint-disable-next-line no-undef
    $('.ui.dropdown').dropdown();
  }

  bandLeaderSupportSelectTargetConnected() {
    // First, set the select's prompt option
    this.bandLeaderSupportSelectTarget.innerHTML = this.#createSelectOption(
      null, BAND_LEADER_SUPPORT_SELECT_PROMPT,
    );
    // Then go fetch the band leader supports
    this.#getBandLeaderSupports();
  }

  bandLeaderSelectTargetConnected() {
    // First, set the select's prompt option
    this.bandLeaderSelectTarget.innerHTML = this.#createSelectOption(
      null, BAND_LEADER_SELECT_PROMPT,
    );
    // Then if the results are already scoped to a BLS, fetch the band leader support's band leaders
    if (this.scopedBandLeaderSupportIdValue) {
      this.#getBandLeaderSupportBandLeaders(this.scopedBandLeaderSupportIdValue);
    // otherwise, go fetch ALL the band leaders
    } else {
      this.#getBandLeaders();
    }
  }

  handleBandLeaderSupportChange({ target }) {
    const selectedValue = parseInt(target.value, 10) || null;
    if (selectedValue) {
      // Go get the newly selected BLS's band leaders
      this.#getBandLeaderSupportBandLeaders(target.value);
    } else {
      // As the BLS has been changed back to null, we need to go fetch the lot
      this.#getBandLeaders();
    }
  }

  #onNoBandLeaderSupportsFound() {
    this.bandLeaderSupportSelectTarget.innerHTML = this.#createSelectOption(
      null, NO_BAND_LEADER_SUPPORTS_FOUND_TEXT,
    );
  }

  #onNoBandLeadersFound() {
    this.bandLeaderSelectTarget.innerHTML = this.#createSelectOption(
      null, NO_BAND_LEADERS_FOUND_TEXT,
    );
  }

  #getBandLeaderSupports() {
    this.#disableBandLeaderSupportSelect();
    API.getBandLeaderSupports()
      .then(({ data }) => {
        this.#insertSelectOptionsFromCollection(this.bandLeaderSupportSelectTarget, data);
        this.#enableBandLeaderSupportSelect();
      })
      .catch(this.#onNoBandLeaderSupportsFound);
  }

  #getBandLeaders() {
    this.#disableBandLeaderSelect();
    API.getBandLeaders()
      .then(({ data }) => {
        this.#insertSelectOptionsFromCollection(this.bandLeaderSelectTarget, data);
        this.#enableBandLeaderSelect();
      })
      .catch(this.#onNoBandLeadersFound);
  }

  #getBandLeaderSupportBandLeaders(id) {
    this.#disableBandLeaderSelect();
    this.#disableBandLeaderSupportSelect();
    API.getBandLeaderSupportBandLeaders(id)
      .then(({ data }) => {
        if (data.length) {
          this.#insertSelectOptionsFromCollection(this.bandLeaderSelectTarget, data);
          this.#enableBandLeaderSelect();
        } else {
          this.#onNoBandLeadersFound();
        }
        this.#enableBandLeaderSupportSelect();
      })
      .catch(this.#onNoBandLeadersFound);
  }

  #collectionSortedByName(data) {
    return _.sortBy(data, 'name');
  }

  #insertSelectOptionsFromCollection(target, collection) {
    const prompt = (
      target === this.bandLeaderSelectTarget
        ? BAND_LEADER_SELECT_PROMPT
        : BAND_LEADER_SUPPORT_SELECT_PROMPT
    );
    // eslint-disable-next-line no-param-reassign
    target.innerHTML = this.#createSelectOption(null, prompt);
    this.#collectionSortedByName(collection).forEach(({ id, name }) => {
      target.insertAdjacentHTML('beforeend', this.#createSelectOption(id, name));
    });
  }

  #createSelectOption(value, text) { return `<option value="${value}"${this.#optionIsSelected(value) ? ' selected' : ''}>${text}</option>`; }
  #optionIsSelected(value) {
    return [this.scopedBandLeaderSupportIdValue, this.scopedBandLeaderIdValue].includes(value);
  }
  #enableBandLeaderSelect() { this.bandLeaderSelectContainerTarget.classList.remove('disabled'); }
  #disableBandLeaderSelect() { this.bandLeaderSelectContainerTarget.classList.add('disabled'); }
  #enableBandLeaderSupportSelect() { this.bandLeaderSupportSelectContainerTarget.classList.remove('disabled'); }
  #disableBandLeaderSupportSelect() { this.bandLeaderSupportSelectContainerTarget.classList.add('disabled'); }
}
