/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['reportModal', 'form', 'failMessage', 'note', 'noteLabel'];

  async openModal(event) {
    try {
      const res = await fetch(`/staff/band_media_discrepancy_reports/${event.target.dataset.id}/modal_content`);
      if (document.querySelector('#popup')) { document.querySelector('#popup').remove(); }
      res.ok ? this.renderModalContent(res) : this.renderError();
    } catch (_error) { this.renderError(); }
  }

  submit(event) {
    const internal_note_warning = '<span style="color:red">&nbsp;Please add an internal note. </span>';
    if (!this.noteTarget.value.length) {
      event.preventDefault();
      if (!this.noteLabelTarget.innerHTML.includes(internal_note_warning)) {
        this.noteLabelTarget.innerHTML += internal_note_warning;
      }
    }
  }

  async renderModalContent(res) {
    const modalHTML = await res.text();
    this.reportModalTarget.innerHTML = modalHTML;
    $('.ui.modal').modal('show');
  }

  closeModal() {
    $('.ui.modal').modal('hide');
  }

  renderError() {
    this.failMessageTarget.innerHTML = `
    <div class="ui negative message">
      <p>Failed to fetch report data.</p>
    </div>
    `;
  }
}
