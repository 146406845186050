/* eslint-disable no-dupe-class-members */
import { Controller } from 'stimulus';

// A generic switch controller that can be used for any two states
// Wrap the switchable content with an HTML element like: <div data-controller="switch">
// Tag the default state items with: <div data-switch-target="default">
// Tag the alternate state items with: <div data-switch-target="alternate">
// Tag the switch button/etc with: <button data-action="click->switch#showAlternate">
// Tag the revert button/etc with: <button data-action="click->switch#showDefault">

// You need to check that other CSS doesn't override the hidden class

export default class extends Controller {
  static targets = ['default', 'alternate'];

  connect() {
    this.hideAlternates();
  }

  showDefault(event) {
    event.preventDefault();

    this.hideAlternates();
    this.defaultTargets.forEach((target) => {
      const updatedTarget = target;
      updatedTarget.hidden = false;
      updatedTarget.classList.remove('hidden');
    });
  }

  showAlternate(event) {
    event.preventDefault();

    this.hideDefaults();
    this.alternateTargets.forEach((target) => {
      const updatedTarget = target;
      updatedTarget.hidden = false;
      updatedTarget.classList.remove('hidden');
    });
  }

  hideDefaults() {
    this.defaultTargets.forEach((target) => {
      const updatedTarget = target;
      updatedTarget.hidden = true;
      updatedTarget.classList.add('hidden');
    });
  }

  hideAlternates() {
    this.alternateTargets.forEach((target) => {
      const updatedTarget = target;
      updatedTarget.hidden = true;
      updatedTarget.classList.add('hidden');
    });
  }
}
